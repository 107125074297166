<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
            <section class="fixed auth-frame">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 mx-auto">
                          <div class="form-title"><h1>パスワード再設定</h1></div>
                            <label class="form-text">パスワードをお忘れの場合、パスワードのリセットが可能ですす。
                              ご登録頂いたメールアドレスを入力し、リセット用のURLをメールでお送りいたします。</label>
                            <div v-if="hasValidationError" class="error-area">
                                <p v-for="item in allValidationError" :key="item.validationText">
                                    ・{{item.validationText}}
                                </p>
                            </div>
                            <form @submit.prevent="send">
                                <div class="form-group">
                                    <label>メールアドレス</label>
                                    <input type="email" class="form-control" placeholder="sesmedia@yurulica.com" v-model="mailAddress">
                                </div>
                                <button type="submit" class="btn-md full-width pop-login form-button" :disabled="mailAddress.length === 0">送信</button>
                            </form>
                        </div>
                    </div>

                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "../../common/api_mixin";
    export default {
        name: "RequestPasswordReissue",
        components: {Footer, Header},
        mixins: [MixIn, ApiMixIn],

        data: function() {
            return {
                isLoading: false,
                mailAddress: "",
                errorMessage: ""
            }
        },
        methods: {
            async send() {
                const response = await this.postApi("auth/requestPasswordReissue", { mailAddress: this.mailAddress }, true);
                if (response) {
                    this.pushResult("再設定用メールを送信しました", "メールをご確認ください");
                }
            }
        }
    }
</script>

<style scoped>

</style>
